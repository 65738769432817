// import toast from "react-hot-toast";

// export function isPercentage(value) {
//     // Regular expression to match a percentage value
//     const percentageRegex = /^(\d+(\.\d+)?%$)|^100%$/;

//     return percentageRegex.test(value);
// }
// export function toastSuccess(message) {
//     toast.success(message);
// }

// export function toastError(error) {
//     toast.error(error.response?.data?.message || error.response?.message || error.message);
// }

import toast from "react-hot-toast";

/**
 * Check if the given value is a valid percentage.
 * @param {string} value - The value to validate.
 * @returns {boolean} - Returns true if valid, otherwise false.
 */
export function isPercentage(value) {
    // Regular expression to match a percentage value
    const percentageRegex = /^(\d+(\.\d+)?%$)|^100%$/;

    return percentageRegex.test(value);
}

/**
 * Display a success toast message with enhanced styling.
 * @param {string} message - The success message to display.
 */
export function toastSuccess(message) {
    toast.success(message, {
        icon: "✅",
        style: {
            border: "1px solid #4caf50",
            padding: "16px",
            color: "#4caf50",
            backgroundColor: "#f0fff4",
            borderRadius: "8px",
        },
        duration: 4000, // Customize the duration (in ms)
    });
}

/**
 * Display an error toast message with enhanced styling.
 * @param {string} error - The error message or error object to display.
 */
export function toastError(error) {
    const errorMessage =
        error.response?.data?.message || error.response?.message || error.message || error.message;

    toast.error(errorMessage, {
        icon: "❌",
        style: {
            border: "1px solid #f44336",
            padding: "16px",
            color: "#f44336",
            backgroundColor: "#fff4f4",
            borderRadius: "8px",
        },
        duration: 5000, // Customize the duration (in ms)
    });
}
