// ** MUI Imports
import Box from '@mui/material/Box'
import { useState, Fragment, useEffect } from 'react'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import { Button, Typography } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import DialogRegister from 'src/views/dialog/DialogRegister'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuthentication } from 'src/redux/auth.Slice'
import { useAuth } from 'src/hooks/useAuth'
import DialogLogin from 'src/views/dialog/DialogLogin'

const AppBarContent = props => {
  // ** Props
  const { logout, user } = useAuth()
  const { hidden, settings, saveSettings } = props
  const [registerFormDialogOpen, setRegisterFormDialogOpen] = useState(false)
  const [loginFormDialogOpen, setLoginFormDialogOpen] = useState(false)

  const toggleRegisterFormDialog = () => {
    setRegisterFormDialogOpen(prev => !prev)
  }
  const toggleLoginFormDialog = (e) => {
    setLoginFormDialogOpen(prev => !prev)
    setRegisterFormDialogOpen(false)
}
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()

  const handleLoginClick = () => {
    if (!isAuthenticated) {
      toggleLoginFormDialog();
    }
    return
  };

  const handleSignUpClick = () => {
    if (!isAuthenticated) {
      toggleRegisterFormDialog();
    }
    return
  };
  useEffect(() => {
    dispatch(checkAuthentication())
  }, [dispatch])
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
      {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
      {user ? (
        <>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '19px', color: '#000' }}>
            {user?.first_name}
          </Typography>
        </>
      ) : (
        <>
          <Button variant='text' onClick={handleLoginClick}>
            Login
          </Button>
          |
          <Button variant='text' onClick={handleSignUpClick}>Sign Up</Button>
        </>
      )}

      <NotificationDropdown settings={settings} />
      <UserDropdown settings={settings} />
      <GoogleOAuthProvider clientId='529194712210-st50mcevrkq97s51kpdf1lhse606tsep.apps.googleusercontent.com'>
        <DialogRegister
          open={registerFormDialogOpen}
          toggle={toggleRegisterFormDialog}
          setRegisterFormDialogOpen={setRegisterFormDialogOpen}
        />
      </GoogleOAuthProvider>
      <GoogleOAuthProvider clientId='529194712210-st50mcevrkq97s51kpdf1lhse606tsep.apps.googleusercontent.com'>
        <DialogLogin
          open={loginFormDialogOpen}
          toggle={toggleLoginFormDialog}
          setLoginFormDialogOpen={setLoginFormDialogOpen}
          setRegisterFormDialogOpen={setRegisterFormDialogOpen}
        />
      </GoogleOAuthProvider>
    </Box>
  )
}

export default AppBarContent
