import { useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/hooks/useAuth'
import { MEDIA_URL } from 'src/network/endpoints'
import DialogRegister from 'src/views/dialog/DialogRegister'
import { checkAuthentication } from 'src/redux/auth.Slice'
import UserIcon from '../../../../assets/images/usericon.png'
import { GoogleOAuthProvider } from '@react-oauth/google'

const UserDropdown = props => {
  const { setLoginFormDialogOpen } = props
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const { settings } = props
  const { logout, user } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [registerFormDialogOpen, setRegisterFormDialogOpen] = useState(false)

  const navigate = useNavigate()
  const { direction } = settings

  useEffect(() => {
    dispatch(checkAuthentication())
  }, [dispatch])

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    setAnchorEl(null)
    if (url) {
      navigate(url)
    }
  }

  const toggleRegisterFormDialog = () => {
    setRegisterFormDialogOpen(prev => !prev)
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  const handleClick = () => {
    setAnchorEl(null)
    if (!isAuthenticated) {
      toggleRegisterFormDialog()
      return
    }
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-end' }}>
        <Avatar
          alt={user?.first_name}
          onClick={handleDropdownOpen}
          sx={{
            width: 42,
            height: 42
          }}
          src={user?.profileImage ? `${MEDIA_URL}${user?.profileImage}` : UserIcon}
        >
          {user?.first_name ? user.first_name.charAt(0) : null}
        </Avatar>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        >
          {user ? (
            <>
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/profile')}>
                <Box sx={styles}>Profile</Box>
              </MenuItem>
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/my-items')}>
                <Box sx={styles}>My Items</Box>
              </MenuItem>
              <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
                Logout
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ p: 0 }} onClick={handleClick}>
              <Box sx={styles}>Login</Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
      <GoogleOAuthProvider clientId='529194712210-st50mcevrkq97s51kpdf1lhse606tsep.apps.googleusercontent.com'>
        <DialogRegister
          open={registerFormDialogOpen}
          toggle={toggleRegisterFormDialog}
          setRegisterFormDialogOpen={setRegisterFormDialogOpen}
        />
      </GoogleOAuthProvider>
    </Fragment>
  )
}

export default UserDropdown
