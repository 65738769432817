import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Signup from '../../assets/images/signin.png'
import DialogLogin from './DialogLogin';
import CloseIcon from "mdi-material-ui/Close";
import { axiosInstance } from 'src/network/adapter';
import { ApiEndpoints } from 'src/network/endpoints';
import { toastError, toastSuccess } from 'src/utils/utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import DialogForgetLogin from './DialogForgetLogin';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from 'src/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { checkAuthentication } from 'src/redux/auth.Slice';
import Google from '../../assets/images/Google.svg'

const validationSchema = yup.object().shape({
    first_name: yup.string().trim().required('First Name is required'),
    last_name: yup.string().trim().required('Last Name is required'),
    email: yup
        .string()
        .required("Email is required")
        .max(50, "The email should have at most 50 characters")
        .matches(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            "Email address must be a valid address"
        ),
    password: yup
        .string("Enter Valid Password Format")
        .min(8)
        .required("Enter Password Address"),
    confirm_password: yup
        .string("Enter Valid Confirm Password Format")
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .min(8)
        .required("Enter Confirm Password Address"),
});

const DialogRegister = props => {
    const auth = useAuth()
    const dispatch = useDispatch();
    const { open, toggle, setRegisterFormDialogOpen } = props
    const [loginFormDialogOpen, setLoginFormDialogOpen] = useState(false)
    const [isTermsChecked, setIsTermsChecked] = useState(false); // Add this state
    
    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        setIsTermsChecked(e.target.checked);
    };
    const toggleLoginFormDialog = (e) => {
        setLoginFormDialogOpen(prev => !prev)
        setRegisterFormDialogOpen(false)
    }
   

    const [loading, setLoading] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })
    const onSubmit = data => {
        setLoading(true);
        let payload = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
        }
        axiosInstance
            .post(ApiEndpoints.AUTH.create, payload)
            .then((response) => response.data)
            .then((response) => {
                toastSuccess(response.message);
                toggle()
                setLoginFormDialogOpen(true);
                //console.log(response.data.token);
            })
            .catch((error) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
                reset()
            })
    }

    useEffect(() => {
        if (open) {
            reset({
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                confirm_password:''
            });
        }
    }, [open, reset]);

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log("Login successful", tokenResponse.access_token)
            let payload = {
                token: tokenResponse.access_token,
            }
            axiosInstance
                .post(ApiEndpoints.AUTH.googleLogin, payload)
                .then((response) => response.data)
                .then((response) => {

                    toastSuccess(response.message);
                    auth.login(response)
                    dispatch(checkAuthentication());
                    setLoginFormDialogOpen(false)
                    //onSuccess()
                    //console.log(response.data.token);
                })
                .catch((error) => {
                    toastError(error);
                })
                .finally(() => {
                    setLoading(false);
                })

        },
        onError: (error) => {
            console.error('Login Failed:', error);
        },
    });
      
    return (
        <>
            <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '30px' } }}
                open={open} fullWidth maxWidth='md' scroll='paper'>
                <DialogContent>
                    <form id='register' onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <img src={Signup} alt={''} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{
                                        fontFamily: 'Ivy Mode ,sans-serif',
                                        fontWeight: 400,
                                        fontSize: { xs: '24px', md: '30px' },
                                        lineHeight: { xs: '32px', md: '37px' },
                                        color: '#5B2E1E'
                                    }}>
                                        Sign Up to Rug Auction
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={toggle}
                                        sx={{ color: (theme) => theme.palette.grey[500] }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ mb: '20px' }} />
                                <Grid item xs={12} md={12}>
                                    <Button fullWidth
                                        variant="outlined"
                                        onClick={login}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1,
                                            borderRadius: '52px',
                                            padding: '10px 20px',
                                            textTransform: 'none',
                                            borderColor: '#905A47',
                                            color: '#905A47',
                                            '&:hover': {
                                                borderColor: '#905A47',
                                                backgroundColor: '#f5f5f5',
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <img src={Google} alt={'Google Logo'} style={{ width: 24, height: 24 }} />
                                            <Typography variant='fm-p2' color={'#000000'}>Sign Up / Sign In with Google</Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Divider flexItem>OR</Divider>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name="first_name"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            error={Boolean(errors.first_name)}
                                                            {...field}
                                                            placeholder="First Name"
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                                {errors.first_name && (
                                                    <FormHelperText sx={{ color: "#ff0000" }}>
                                                        {errors.first_name.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name="last_name"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            error={Boolean(errors.last_name)}
                                                            {...field}
                                                            placeholder="Last  Name"
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                                {errors.last_name && (
                                                    <FormHelperText sx={{ color: "#ff0000" }}>
                                                        {errors.last_name.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            error={Boolean(errors.email)}
                                                            {...field}
                                                            placeholder="Enter Email address"
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                                {errors.email && (
                                                    <FormHelperText sx={{ color: "#ff0000" }}>
                                                        {errors.email.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl fullWidth >
                                                <Controller
                                                    name='password'
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <TextField
                                                            variant='outlined'
                                                            error={Boolean(errors.password)}
                                                            value={value}
                                                            onChange={onChange}
                                                            id='password'
                                                            placeholder='Enter your New Password'
                                                            type={showNewPassword ? 'text' : 'password'}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                                            onMouseDown={e => e.preventDefault()}
                                                                        >
                                                                            {showNewPassword ? <VisibilityOutlinedIcon sx={{ color: '#905A47' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#905A47' }} />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.password && (
                                                    <FormHelperText error>
                                                        {errors.password.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name='confirm_password'
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <TextField
                                                            variant='outlined'
                                                            error={Boolean(errors.confirm_password)}
                                                            value={value}
                                                            onChange={onChange}
                                                            id='confirm_password'
                                                            placeholder='Enter your Confirm Password'
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                            onMouseDown={e => e.preventDefault()}
                                                                        >
                                                                            {showConfirmPassword ? <VisibilityOutlinedIcon sx={{ color: '#905A47' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#905A47' }} />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.confirm_password && (
                                                    <FormHelperText error>
                                                        {errors.confirm_password.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box display="flex" alignItems={'center'}>
                                                <Checkbox
                                                    sx={{ color: '#905A47' }}
                                                    onChange={handleCheckboxChange} // Update the checkbox value
                                                />
                                                <Typography variant="fm-p3" color={'#905A47'}>
                                                    By Continuing, you agree to our Terms and conditions
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <LoadingButton
                                                sx={{ width: '100%', bgcolor: '#000000' }}
                                                size='large'
                                                type='submit'
                                                form='register'
                                                variant='contained'
                                                loading={loading}
                                                disabled={!isTermsChecked} // Disable if T&C is not checked
                                            >
                                                {loading ? "Submitting..." : "Save"} 
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                <Typography variant='body2'>
                                                    Already have an account?
                                                </Typography>
                                                <Typography variant='body2'>
                                                    <Button variant='text' onClick={toggleLoginFormDialog}>
                                                        Sign in instead
                                                    </Button>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>

            </Dialog>
            <GoogleOAuthProvider clientId="529194712210-st50mcevrkq97s51kpdf1lhse606tsep.apps.googleusercontent.com">
                <DialogLogin
                    open={loginFormDialogOpen}
                    toggle={toggleLoginFormDialog}
                    setLoginFormDialogOpen={setLoginFormDialogOpen}
                    setRegisterFormDialogOpen={setRegisterFormDialogOpen}
                />
            </GoogleOAuthProvider>

        </>
    )
}

export default DialogRegister
